import React, { Fragment } from "react";
import Countdown from "react-countdown-now";

export default function Deadline({ task, onDeadline }) {
  const fixedDeadline = task.deadline[task.deadline.length - 1] === 'Z' ? task.deadline : task.deadline + 'Z';
  const deadline = Date.parse(fixedDeadline) - task.delta;

  let deadlineClass = "text-danger";

  const handleDeadline = () => {
    onDeadline && onDeadline(true);
    deadlineClass += " d-none";
  };

  if (deadline < Date.now()) handleDeadline();

  return (
    <Fragment>
      <div className={deadlineClass}>
        <Countdown date={deadline} precision={1} onComplete={handleDeadline} daysInHours />
      </div>
    </Fragment>
  );
}
